///  Description Private mixin used by export-to-json used to export a list of maps.
///  
///  @access private
///  @group JS
///  @since 1.0.0
@mixin _maps-to-json($maps, $map_names) {
  @if length($maps) != length($map_names) {@error "Mixin export-to-json, expects the $maps list lenght to match the $map-names list length."}
  $json: ();
  $i:0;
  
  @while $i<length($maps) {
    $elem:'';
    $data:();
    $obj: ();
    @each $key, $value in nth($maps, $i+1) {
      $value: strip-unit($value);
      $elem: ' "#{$key}" : "#{$value}" ';
      $data: append($data, $elem, comma);
    }
    $obj : ' "#{nth($map_names, $i+1)}": {#{$data}}';
    $json: append($json, $obj, comma);
    $i: $i + 1;
  }
  #scss-vars::before {
    content: '{#{$json}}';
    font-size: 0;
    opacity: 0;
  } 
}


///  Description Exports a map or a list or a list of maps into JSON and attaches them to the body, via body::before->content.
///  It does not accept nested maps.
///  IMPORTANT: Requires the use of an element with an id of scss-vars, where the json string will be placed.
///  Based on the code at: https://css-tricks.com/making-sass-talk-to-javascript-with-json/
///  
///  @param {map} $maps - A map where keys are the names of the properties and the values are a map for the property. 
///         I.e. $maps: (  colors: (
///                                 base:#000, 
///                                 white: #FFF
///                              ), 
///                       breakpoints: (
///                                 sm: 480px, 
///                                 md: 1024px
///                              )
///                      );
///  @group JS
///  @since 1.0.0
@mixin export-to-json($maps) {
  $map_names: map-keys($maps);
  $list: map-values($maps);
  @include _maps-to-json($list, $map_names)
}