.contact {
	position: relative;
	padding-bottom: 90px;
	background-color: c(gray-light);
	&__container {
		background-color: c(gray-light);
		padding-top: 80px;
	}
	&__box {
		padding-top: 100px;
		@include over (md) {
			right: 100px;
			bottom: 50px;
		}
	}
	&__form-item {
		display: flex;
		padding-bottom: 30px;
		position: relative;
	}
	&__ttl {
		@extend %title;
		font-size: 25px;
		line-height: 58px;
		padding-bottom: 50px;
		&-box {
			max-width: 365px;
		    width: 100%;
		    margin-left: 45px;
			@include under(sm) {
				margin-left: 0;
			}
		}
	}
	&__sbttl {
		font-family: f(futura);
		font-size: 14px;
		line-height: 1;
		padding-bottom: 50px;
	}
	&__submit {
		@extend %button;
		max-width: 100%;
		background-color: c(white) !important;
		&:hover {
			background-color: black !important;
			color: c(white);
		}
	}
}
