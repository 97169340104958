%title,
.title {
    font-family: f(futura);
    font-weight: bold;
    font-size: 45px;
    line-height: (4/3);//en paréntesis para que sass haga el cálculo
    padding-top: 20px;
    @include under (mobile) {
    	font-size: 40px;
    }
}

%gral-title,
.gral-title {
    font: {
        family: f(futura);
        size: 35px;
    }
}

%subtitle,
.subtitle {
    font-family: f(futura);
    font-size: 18px;
    padding-top: 20px;
}

%title-blog,
.title-blog {
    font: {
        family: f(futura);
        weight: bold;
        size: 30px;
    }
    line-height: (4/3);
    @include under (mobile) {
        font-size: 35px;
    }
}


