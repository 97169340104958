/*_________________________ Grid _________________________ */

.grid {
	$gutter: s(1, gutter);
	$gutter-sm: s(1, gutter-sm);
	$box--sm-width: 315px;
	$logo-width: 148px;
	$quotes: 620px;

//Mixins agregan los estilos independientemente de los breaks, especialmente para el sm-styles que se usa en dos breaks diferentes
	@mixin full-width-styles {
		flex-basis: 50%;
		&:nth-of-type(odd) {
			padding-right: $gutter;
		}
		&:nth-of-type(even) {
			padding-left: $gutter;
		}
	}
	@mixin sm-styles {
		flex-basis: 100%;
		min-width: 100%;
		&:nth-of-type(odd) {
			padding-right: 0;
		}
		&:nth-of-type(even) {
			padding-left: 0;
		}
	}

	&__row {
		position:relative;
		width: 100%;
		min-height: 1px;
	}
	&__container {
		position:relative;
		padding: 0 $gutter;
		display: flex;
		flex-wrap: wrap;
		margin-left: auto;
		margin-right: auto;
		max-width: 1200px;
		width: 100%;
		&--no-pad,
		&--banner {
			padding: 0;
		}
		&--no-wrap {
			flex-wrap: nowrap;
		}
		&--left {
			@include between(xs, md) {padding-left: 318px;}
		}
	}

	&__nested-container {//ayuda a posicionar las columnas a partir del container
		&--main {
			max-width: 1028px;
			width: 100%;
			margin-left: auto;
			margin-right: 0;
			display: flex;
			flex-wrap: wrap;
		}
		&--cuadritos-home {//vinculado al flex de las columnas
			max-width: 860px;
			width: 100%;
			margin-right: auto;
			margin-left: 0;
			display: flex;
			@include under(sm) {
				flex-wrap: wrap;
			}
		}

		&--cuadritos-paralelos {
			position: relative;
			max-width: 860px;
    		width: 100%;
    		margin-right: 160px;
    		margin-left: auto;
			display: flex;
			&--other-width {
				width: 100%;
				max-width: 986px;
				margin-right: 0px;
				@include under($quotes) {
					flex-direction: row;
					flex-wrap: wrap;
				}
			}
			@include under(md) {
				margin: 0;
			}
			@include under(mobile) {
				flex-wrap: wrap;
			}
			&--wrap {
				@include under(contact-sm) {
					flex-wrap: wrap;
				}
			}
		}
		&--left {
			max-width: 1000px;
    		width: 100%;
    		margin-right: auto;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			@include over(sm) {margin-left: 200px;}
			@include under(md) {justify-content: space-around;}
			@include under(mobile) {flex-wrap: wrap;}
		}
	}

	&__col {
		&,
		&-1-1,
		&-1-2,
		&-1-3,
		&-2-3 {
			order: 0;
			@include under(sm) {
				&--order-1 { order: 1;}//usado en el responsivo. para la imagen en las secciones de Caso de Éxtio (ver Modelo de sección Caso de Éxito en la vista cltvo/front/grid )
				&--order-2 { order: 2;}//usado en el responsivo. para la imagen en las secciones de Caso de Éxtio (ver Modelo de sección Caso de Éxito en la vista cltvo/front/grid )
			}
			position:relative;
		}
		&-1-1 {
			width: 100%;
			position: relative;
			margin-right: auto;
			margin-left: auto;
			&--portada, &--privacy, &--faq {
				display: flex;
				align-items: center;
				max-width: 700px;
			}
			&--beneficios {
				max-width: 500px;
				@include under (md) {
					margin-left: 0;
				}
			}
		}
		&-1-2 {

			//implementación
			@include full-width-styles;

 			@include under(sm) {
        		@include sm-styles;
      		}

			&--caso-exito {
				@include under (cuadritos-home) {
					flex-basis: 100%;
				}
			}

			&--logo {
				min-width: 0%;
				max-width: $logo-width !important;
				width: 100%;
				@include under (sm) {
					max-width: 100%;
				}
			}
			&--menu {
				max-width: calc(100% - #{$logo-width});
				width: 100%;

				@include under (sm) {
					max-width: 100%;
				}
				@include flex-static-col-with-adaptable-cols(104px, 'colabora', 'menus-container');//.grid__col-1-2--menu--colabora, .grid__col-1-2--menu--menus-container
				&--colabora {
					$margin-left: 81px;
					$starting-break: 1200px;
					$unit: 1px;
					margin-left: 81px;
					@while ($margin-left > 0) {
					 	@include under(($starting-break - $unit)) {
							margin-left: $margin-left - $unit;
					 	}
					  	$starting-break: $starting-break - $unit;
					  	$margin-left: $margin-left - $unit;
					}
					@include under($starting-break - $margin-left) {display: none;}
				}
			}
			&--contact {
				@include full-width-styles;

				// @include under(cuadritos-home) {
				// 	flex-basis: 100%;
				// 	padding: 0px !important;
				// }
				@include under(contact-sm) {
					@include sm-styles;
					flex-basis: 100%;
					padding: 0px !important;
				}
			}
			&--footer {
				@include full-width-styles;

				@include under(contact-sm) {
					@include sm-styles;
				}
			}
		}

		&-1-3 {
			@include ternary-column-maker($gutter: $gutter)
			&--fixed {
				max-width: $box--sm-width;
				width: 100%;
				@include under (sm) {max-width: 100%;}
			}
			&--static-sm {
				@include over (xs) {
					right: 0;
					max-width: 315px;
					height: 300px;
					width: 100%;
				}
				&--other-width {
					@include over (xs) {
						max-width: 334px;
					}
					@include under(md) {
						position: absolute;
						top: 0px;
						right: 0px;
					}
					@include under($quotes) {
						position: relative;
						width: 100%;
					}
				}
			}
		}

		&-2-3 {
			&--flex {
				max-width: calc(100% - #{$box--sm-width});
				width: 100%;
				padding-left: $gutter*2;//el padding de ambas columnas (1-3--fixed y 2-3--flex) se lo damos aquí
				@include under (sm) {
					max-width: 100%;
					padding: 0;
				}
			}
			&--static-lg {
				@include over (xs){
					max-width: 500px;
					height: 240px;
					width: 100%;
					padding-left: 30px;
				}
				@include under (md) {
					padding-left: 0;
				}
				&--other-width {
					height: auto;
					@include over (xs){
						padding-left: 0;
						max-width: 652px;
					}
					@include under(md) {
						max-width: 580px;
						height: auto;
					}
				}
			}

		}

		&-irregular {
			@include over(cuadritos-home) {
				@include flex-static-col-with-adaptable-cols(316px, '2-5--static', '3-5--flex') //funcionan bien hasta 672px
				&--2-5--static { min-width: 40%;}
			}
			&--2-5--static,
			&--3-5--flex {
				@include under(sm) {//vinculado al flex wrap dell contenedor
					 flex: 0 0 100%;
				}
			}
		}
		&--binary-with-ternary-style-paddings {
			@include binary-column-maker($sm:2, $lg:3, $gutter: $gutter, $container:5)
			@include over(sm) {
				&:nth-of-type(odd) {
				   padding-right: gutter-calculator($gutter, outer);
				}

				&:nth-of-type(even) {
				     padding-left: gutter-calculator($gutter, inner);
				}
			}
			@include under(sm) {
				@include binary-column-padding($reset: true);
			}
		}
	}

	&__box {
		position:relative;
		width: 100%;
		&--logo {
			max-width: $logo-width;
		}
		&--sm {
			max-width: $box--sm-width;
			@include under(sm) {
				max-width: 100%;
			}
		}
		&--xs--right {
			max-width: 279px;
			text-align: right;
			margin-left: auto;
			margin-right: 0;

			@include under(sm) {
				margin-left: 0;
				margin-right: auto;
			}
		}
		&--center {
			margin: 0 auto;

			@include under(sm) {
				margin-left: auto;
				margin-right: auto;
			}
		}

		&--cuadritos-home {
			height: 255px;
			&--left,
			&--right{
				position: relative;
				height: 255px;
				margin-bottom: s(1, gutter);
				max-width: 316px;
				margin-left: auto;
				margin-right: auto;
			}
			@include over(sm) {
				&--left {
					margin-right: auto;
					margin-left: 0;
				}
				&--right {
					margin-left: auto;
					margin-right: 0;
				}
			}
		}
	}

	&__nested-box {
		&--cuadritos-home {
			position: relative;
			width: 100%;
			height: 255px;// movemos el height a cuadritos home, porque necesitamos cambiar la posicion absoluta de esta clase por posición relativa
			z-index: 2;
			padding: 35px 42px;
			&--absolute {
				position: absolute;
				&.home__audience--box--empresas {
					opacity: 0;
					top: 200px;
					transition: all 0.7s ease-out;
					transition-delay: 0s;
					&.show {
						top: 0;
						opacity: 1;
					}
				}

				&.home__audience--box--voluntarios-alt {
					opacity: 0;
					top: 200px;
					transition: all 0.7s ease-out;
					transition-delay: 0.15s;
					&.show {
						top: s(4, gutter);
						opacity: 1;
						@include under(sm) {
							top: 0px;
						}
					}
				}

				&.home__audience--box--universidades-alt {
					opacity: 0;
					top: 200px;
					transition: all 0.7s ease-out;
					transition-delay: 0.3s;
					&.show {
						top: s(4, gutter);
						opacity: 1;
						@include under(sm) {
							top: 0px;
						}
					}
				}

				&.home__audience--box--instituciones-alt {
					opacity: 0;
					top: 200px;
					transition: all 0.7s ease-out;
					transition-delay: 0.45s;
					&.show {
						top: 0;
						opacity: 1;
					}
				}
			}

			&--down {
				@include over(cuadritos-home) {
					top: s(4, gutter);
				}
			}
		}
	}
}


/*Grid Debug*/
@if ($debug-grid) {
	.debug {
		.mb-lg--debug-only {
			margin-bottom: s(5, gutter);
		}
		.grid {
			&__row {
				position:relative;
				background-color: c();
				min-height: 200px;
			}
			&__container {
				position:relative;
				background-color: c(blue);
				min-height: 200px;
				margin-bottom: 20px;
			}
			&__col-1-1,
			&__col-1-2,
			&__col-1-3 {
				&:nth-of-type(even) {
		 			background-color: c(aqua);
		 			min-height: 100px;
				}
				&:nth-of-type(odd) {
					background-color: c(orange);
		 			min-height: 100px;
				}
				&--contain-fixed {
					background-color: c(yellow);
				}
			}

			&__col-1-1--portada {
				background-color: c(orange);
				min-height: 300px;
			}

			&__col-2-3--static-right,
			&__col-1-3--static-left {
				height: 200px;
				background-color: c(aqua);
			}

			&__col-1-3--static-left  {
				background-color: c(yellow);
				opacity: 0.5;
			}

			&__box {
				position: relative;
				background-color: (white);
				min-height: 50px;
			}
			&__nested-box {
				&--cuadritos-home  {
					background-color: c(yellow-light);
				}
			}
		}
		.image {
			&__col {
				&--right,
				&--left {
					@include center('y');
				}

				&--right {
					right: 0;
				}
				&--left {
					left: 0;
				}
			}
		}
	}
}
