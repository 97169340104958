/// Crea paddings para columnas binarias
///
///
/// @param {number} gutter: padding de la columna
///
/// @example    .col-1-2 {@include binary-column-padding(50);}
///
/// @author Diego Villaseñor y Cecilia M. Borja
/// @since 0.0.1
///

@mixin binary-column-padding($gutter:0, $reset: false) {
    @if($reset) {
		&:nth-of-type(odd) {
	        padding-right: 0;
	    }

	    &:nth-of-type(even) {
	        padding-left: 0;
	    }
    } @else {

	    $gutter: $gutter/2;
	    &:nth-of-type(odd) {
	        padding-right: $gutter;
	    }

	    &:nth-of-type(even) {
	        padding-left: $gutter;
	    }
    }
}
