.alert {
	width: 100%;
	border: 0;
	margin: 0 auto;
	padding: 12px;
	display: none;
	box-shadow: 0 0 5px rgba(0,0,0,0.3);
	border-radius: 0 0 5px 5px;
	&__success,
	&__danger {
		width: 100%;
		position: relative;
	}
	&__success {
		background-color: c(light);
		border-bottom: 3px solid c(success);
	}
	&__danger {
		background-color: c(light);
		border-bottom: 3px solid c(error);
	}
	&__container  {
		background-color: c(white);
		top: -200px;
		left: 50%;
		width: 100%; max-width: 500px;
		margin-left: -250px;
		padding: 0 20px;
		z-index: 999999;
		position: fixed;
		text-align: center;
		@include ease-transition;
		@include under(500px) {
			left: 0;
			margin: 0;
			padding: 0;
		}
	}
	&__close {
		top: 12px;
		right: 12px;
		width: 20px;
		height: 20px;
		color: black;
		cursor: pointer;
		opacity: 0.4;
		position: absolute;
		font-size: 18px;
		font-weight: bold;
		line-height: 1;
		border-radius: 2px;
		background: rgba(0,0,0,0.1);
		&:hover {
			background: rgba(0,0,0,0.2);
		}
	}
	&__text {
		@extend %paragraph !optional;
		color: c(base);
		font-size: 14px;
		line-height: 21px;
		font-family: f(futura);
	}
}
