.blog {
	//border-top: 1px solid c(gray-light);
	overflow: auto;
	padding-top: 50px;
	$social: 1200px;

	&__meta {
		top: 0;
		left: 0;
		width: 200px;
		color: c(gray) !important;
		z-index: 1;
		position: absolute;
		text-align: right;
		font-family: f(daxline);
		background-color: c(white);
		padding: 0 12px 0 0;

		@include under($social) {
			display: none;
			width: 100%;
			margin: 20px auto 30px auto;
			position: relative;
			text-align: center;
		}

		&--p {
			display: block;
			line-height: 130%;
			margin-bottom: 10px;
		}

		&--social {
			margin-bottom: 20px;
			.fa {
				width: 25px;
				cursor: pointer;
				padding: 2px 0;
				display: inline-block;
				font-size: 18px !important;
				text-align: center;
				margin-left: 10px;
			}
		}
	}

	&__top {
		width: 100%;
		padding: 50px 12px 30px 12px;
		position: relative;
		border-bottom: 1px solid c(gray-light);
		@include under(xs) {
			padding: 30px 0;
		}

		&.single {
			padding: 50px 0 30px 0;
		}

		&--video {
			width: 820px;
			height: 460px;
			display: block;
			position: relative;
			margin-left: auto;
			margin-bottom: 30px;
			background-color: c(gray-light);
			@include under('880px') {
				width: 100%;
				height: 0;
				padding-top: 56%;
			}
			@include under(md) {
				margin-right: auto;
			}

			iframe {
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				position: absolute;
			}

		}

		&--thumb {
			width: 820px;
			height: 500px;
			display: block;
			margin-left: auto;
			margin-bottom: 20px;
			background-color: c(gray-light);
			@include bg-cover;
			@include under('880px') {
				width: 100%;
				height: 0;
				padding-top: 65%;
			}
			@include under(md) {
				margin-right: auto;
			}
		}

		&--ttl {
			@extend %title;
			display: block;
			font-size: 35px;
			padding-top: 0;
			line-height: 45px;
			margin-bottom: 5px;
			@include under(xs) {
				font-size: 28px;
			}
		}

		&--header {
			max-width: 490px;
			margin-left: auto;
		}

		&--header {
			@include under(md) {
				max-width: 428px;
				text-align: left;
			}
		}
		&--content {
			@include under(md) {
				max-width: 680px;
				margin-right: auto;
			}
		}

	}

	&__item {
		width: 33.2%;
		padding: 0 12px;
		margin-bottom: s(3);
		@include under(md) {
			width: 49.9%;
		}
		@include under(xs) {
			width: 100%;
			padding: 0;
		}

		&--inner {
			border-bottom: 1px solid c(gray);
			padding-bottom: s(1);
		}

		&--ttl {
			@extend %title;
			display: block;
			font-size: 30px;
			padding-top: 0;
			line-height: 38px;
			margin-bottom: 5px;
		}

		&--link {
			@extend %paragraph;
			color: c(aqua-alt);
			cursor: pointer;
			display: inline-block;
			font-size: 18px;
			line-height: 150%;
			margin-bottom: 20px;
			text-transform: capitalize;
			&:hover {
				text-decoration: underline;
			}
		}
		&--thumb {
			width: 100%;
			height: 0;
			display: block;
			padding-top: 65%;
			margin-bottom: 20px;
			background-color: c(gray-light);
			@include bg-cover;
		}

		&--intro,
		&--intro * {
			@extend %paragraph;
			display: block;
			font-size: 20px;
			line-height: 32px;
			margin-bottom: s(2);
		}

		&--intro {
			&.align-left {
				text-align: left !important;
			}
			@include under(md) {
				margin: 0 auto 20px auto;
			}
			&.single,
			&.single * {
				font-size: 20px;
			}
		}

	}

	&__content {
		max-width: 66%;
		margin-left: auto;
		@include under(md) {
			margin: 30px auto !important;
		}
		@include under(contact-sm) {
			max-width: 100%;
		}

		&--txt {
			max-width: 66%;
			margin-left: auto;
			@include under(md) {
				max-width: 578px;
				width: 100%;
				margin: 0 auto !important;
			}

			&.single {
				margin: 30px 0 30px auto;
				max-width: 512px;
				font-size: 20px;
    			line-height: 32px;
			}
			&.single * {
				@extend %paragraph;
				display: block;
				font-size: 20px;
    			line-height: 32px;
			}
			&.single p {
				margin-bottom: s(1);
				font-size: 20px;
    			line-height: 32px;
			}

		}

		&--quote {
			font-size: 18px;
    		line-height: 30px;
			font-family: f(daxline);
			//padding: s(1) 308px s(1) 20px;
			max-width: 317px;
			margin-left: 210px;
			margin-bottom: 50px;
			text-transform: uppercase;
			color: #000;
			//border-left: 4px solid c(gray-light);
			@include under(md) {
				margin: 30px auto !important;
				max-width: 512px;
				padding-right: 0;
			}
			* {
				font-size: 18px;
    			line-height: 30px;
				font-family: f(daxline);
				//text-transform: uppercase;
			}
		}
	}

	&__wrap {
		@include under(mobile) {
			padding: 0px;
		}
	}

	&__gallery {
		margin: s(3) 0;
		display: flex;
		flex-wrap: wrap;

		&--item {
			width: calc(100% / 3);
			height: 0;
			cursor: pointer;
			border: 10px solid c(white);
			display: inline-block;
			padding-top: 25%;
			@include ease-transition;
			&:hover {
				opacity: 0.7;
			}
			@include bg-cover;
			@include under(cuadritos-home) {
				width: 50%;
				padding-top: 37.5%;
			}
			@include under(contact-sm) {
				width: 100%;
				padding-top: 70%;
			}

		}
	}

	&__lightbox {
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 99;
		display: none;
		position: fixed;
		text-align: center;
		background-color: rgba(255,255,255,0.85);

		&--close {
			top: 10px;
			left: 10px;
			color: c(purple-alt);
			cursor: pointer;
			z-index: 100;
			display: none;
			padding: 6px 17px 12px 17px;
			position: fixed;
			font-size: 30px;
			line-height: 40px;
			-ms-user-select: none;
			-moz-user-select: none;
			-webkit-user-select: none
		}

		&--img {
			top: 50%;
			left: 50%;
			width: auto;
			height: auto;
			margin: 0 auto;
			position: absolute;
			max-width: 85vw;
			max-height: 85vh;
			transform: translate(-50%, -50%);
		}

		&--next,
		&--prev {
			top: 50%;
			color: c(purple-alt);
			cursor: pointer;
			z-index: 100;
			display: none;
			padding: 6px 17px 12px 17px;
			position: fixed;
			font-size: 35px !important;
			transform: translateY(-50%);
			line-height: 40px;
			-moz-user-select: none;
			-webkit-user-select: none;
			&.fa { display: none; }
		}
		&--prev { left: 10px; }
		&--next { right: 10px; }
	}

	&__related {
		&--divisor {
			width: calc(100% - 24px);
			margin: 0 auto;
			position: relative;
			margin-top: s(5);
			border-top: 1px solid c(gray);
		}
		&--label {
			top: -10px;
			left: 0;
			color: c(gray);
			display: inline-block;
			position: absolute;
			font-size: 18px;
			line-height: 100%;
			padding-right: 10px;
			background-color: c(white);
		}
	}

	&__nav {
		width: 100%;
		max-width: 1028px;
		margin-left: auto;
		margin-bottom: s(6);
		.active {
			color: c(purple-alt);
			text-decoration: underline;
		}
		li {
			color: c(purple);
			cursor: pointer;
			display: inline-block;
			font-size: 17px;

			a {
				&:hover { text-decoration: underline; }
			}

			a,span {
				padding: 2px 8px;
			}
		}
	}

}
