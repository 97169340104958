.universities {
	&__select {
		border-bottom: 1px solid black;
    	border-radius: 0px;
		text-align-last: left;
		padding-left: 10px;
		color: c(purple-alt);
		font-size: 17px;
		line-height: 30px;
    	letter-spacing: 1px;
	}
	&__options {
		color: c(purple-alt);
		font-size: 18px;
	}
	&__arrow {
		left: 0px;
	}
	&__box {
		width: 100%;
		max-width: 317px;
		@include under(sm) {
			margin-left: auto;
			margin-right: auto;
		}
	}
	&__section {
		@include under(sm) {
			padding-top: 50px;
		}
	}
}
