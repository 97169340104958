///
/// An "@media" mixin that responds to a bigger-than-given screen width.
///
/// 
/// @param  map key or string $min   Map with the names of breaks and their units or only the desired unit or the Map key.
/// @param  map key or string $max   Map with the names of breaks and their units or only the desired unit or the Map key.
/// @author Diego Villaseñor
/// @since 1.0.0
/// @content 
////
@mixin over($break) {
	 @if map_has_key($breaks, $break) {
    @media only screen and (min-width: map_get($breaks, $break)) {
      @content;
    }
  }
  @else {
    @media only screen and (min-width: $break) {
      @content;
    }
  }
} 