@mixin section-audience($color) {
	&--#{$color} {
		background-image: url('../../images/bg-#{$color}.svg');
	}
}

.section-audience__divisor {
  	width: 100%;
	height: 200px;
	background-size: cover;
	background-attachment: fixed;
  	@each $class, $color in $colors {
    @include section-audience($class);
  }
}