.events {
	position: relative;
	margin-bottom: 130px;
	&__intro {
		color: c(purple-alt);
		&--ttl {
			@extend %gral-title;
			font-weight: bold;
		}
		&--p {
			@extend %subtitle;
			color: c(purple-alt);
			line-height: 30px;
		}
	}
	&__activities {
		&--bg-img {
			background-image: url(../../images/bg-purple.svg);
    		background-size: cover;
    		width: calc(100% - 40px);
    		min-height: 170px;
    		position: absolute;
    		left: 20px;
    		@include under (sm) {
    			left: 0px;
				width: 100%;
    		}
		}
		&--dates {
			@extend .subtitle;
			color: c(white);

		}
		&--ttl {
			@extend %title-blog;
			color: c(white);
			padding-top: 30px;

		}
		&--target {
			color: c(white);
			font {
				family: f(daxline);
				size: 18px;
			}
			text-transform: uppercase;
			padding-top: 10px;
		}
		&--details {
			color: c(white);
			padding-top: 30px;

		}
		&--label {
			font-family: f(futura);
			font-size: 16px;
			color: c(white);
			padding-top: 10px;

		}
		&--content {
			font-family: f(daxline);
			font-size: 17px;
			text-transform: uppercase;
			color: c(white);
			padding: 10px 0;
			border-bottom: 1px solid white;
		}
		&--button {
			@extend %button;
			font-family: f(daxline) !important;
			font-size: 13px;
			padding: 5px;
			margin: 40px;
			&:hover {
				background-color: black;
				color: c(white);
			}
		}
	}

	&__single {
		background-color: c(purple);
		&--description {
			@extend %paragraph;
			color: c(white);
		}
		&--pagination {
		    font-family: f(futura);
		    font-size: 18px;
			color: c(white);
			padding: 50px 0;
		}
	}
	&__box {
		padding: 20px 20px 50px 20px;
		width: 100%;
		max-width: 300px;
    	min-height: 600px;
    	margin-bottom: 50px;
    	background-color: c(purple);
		@include under(cuadritos-home) {
			max-width: 100%;
		}
		&-right {
			max-width: 500px;
    		margin: 50px 0px 50px 55%;
    		@include between (sm, md) {margin-left: 52%;}
    		@include between (xs, sm) {margin-left: 32%;}
    		@include under (xs) {margin-left: 0%;}
		}
		&-single {
			padding: 220px 20px;
			width: 480px;
    		min-height: 600px;
    	}

		// Background loop
		$purpleLoop: #9A9CCD, #605C8E;
		@for $i from 1 through length($purpleLoop) {
			$purpleLoop: append($purpleLoop, (nth($purpleLoop, $i)));
		}
		$purpleLoopLength: length($purpleLoop);
		@for $i from 1 through $purpleLoopLength {
			&:nth-of-type(#{$purpleLoopLength}n+#{$i}) {
  				background-color: nth($purpleLoop, $i);
			}
		}
	}
}
