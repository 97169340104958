.header {
	width: 100%;
	height: 150px;
	@media only screen and (max-width: b(md)) {
		display: none;
	}
	&__logo {
		float: left;
		width: 188px;
		height: 100%;
		padding-top: 10px;//por qué esto tiene padding-bottom
		@include under(md) {
			height: 48px;
			display: none;
			width: 150px;
			position: relative;
			top: -3px;
			padding-bottom: 0;
			padding-top: 10px;
		}
		&--responsive {
			display: none;
			@include under(md) {
				display: flex;

			}
		}

		&--img {
			width: 100%;
			height: auto;
			max-width: 115px;
			@media only screen and (max-width: b(md)) {
				margin: 0;
			}
			&--responsive {
				@include under(md) {
					max-width: 85px;
				}
			}

			&--mobile {
				width: 33px;
				position: relative;
				top: -6px;
				margin-right: 7px;
				@include over(md) {
					width: 81px;
					top: -1px;
				}
			}

			&-text--mobile {
				position: relative;
				top: -4px;
				@include over(md) {
					display: none;
				}
			}
		}
	}

	&__menu {
		display: flex;
		padding: 35px 0px 24px;
		justify-content: space-between;
		&--general {
			width: 100%;
			@include make-divisor(c(aqua), 1px);
			@include under (md) {
				border-bottom: 0px solid;
			}
		}
		&--item {
			position: relative;
			list-style: none;
			text-align: center;
			&--black {
				&:hover {
					text-decoration: underline;
				}
			}
			&:hover {
				&-txt {
					color: c(gray-light);
				}
				// para los submenus
				.header__blog-menu--holder,
				.header__submenu--holder {
					display: block;
				}
			}
			@include link-states {
				&:active, &.selected {
					color: c(white);
					//background-color: c(aqua);
				}
			}
			&-link {
				display: block;
			}
			&-txt {
				@extend %link;
				color: c(dark);
				font-size: 16px;
				@include ease-transition;
				&--boletin {
					color: c(white);
					background-color: c(aqua);
					padding: 5px 25px 2px;
					&:hover {
						background-color: c(purple);
					}
				}
			}
		}

		&--has-submenu {
			position: relative;
			z-index: 2;
			display: block;
		}

		&--audience {
			@extend %link-secondary;
			padding-top: 10px;
			@include color-modifiers($is_link:true);
			&--ttl {
				font-size: 16px;
				line-height: 20px;
				position: absolute;
				bottom: 22px;
			}
		}
	}
	&__blog-menu,
	&__submenu {
        cursor: pointer;
        position: relative;

        &--holder {
            top: 0;
            left: -75px;
            width: 150px;
            z-index: 1;
            display: none;
            @include center('x');
            padding-top: 25px;
        }

        &--item {
        	font-size: 14px;
           	color: c(white);
		display: block;
            padding: 15px 5px;
            background-color: c(aqua);
            @include ease-transition;
            &:hover {
            	font-family: f(futura);
                	background-color: c(aqua-hover);
            }
            &--cuates {
            	background-color: c(purple);
            	&:hover{
            		background-color: c(purple-hover);
            	}
            }
            &--voluntarios {
            	background-color: c(orange);
            	&:hover{
            		background-color: c(orange-hover);
            	}
            }
            &--empresas {
            	background-color: c(blue);
            	&:hover{
            		background-color: c(blue-hover);
            	}
            }
            &--instituciones-aliadas {
            	background-color: c(green);
            	&:hover{
            		background-color: c(green-hover);
            	}
            }
            &--universidades {
            	background-color: c(yellow);
            	&:hover{
            		background-color: c(yellow-hover);
            	}
            }
        }
    	}

	&--fixed {
		top: 0;
		left: 0;
		width: 100%;
		z-index: 10;
		display: none;
		position: fixed;
		background: c(white);
		box-shadow: 0 0 10px rgba(0,0,0,0.15);
		&:hover {
			overflow: visible !important; //hace visible el sub-menu Blog
		}
		@media only screen and (max-width: b(md)) {
			height: 45px !important;
			display: block !important;
		}
		.header__menu {
			@media only screen and (max-width: b(md)) {
				display: none;
			}
			padding-top: 20px;
		}
		.header__menu--audience {
			padding-top: 5px;
		}
	}
	&__mobile {
		top: 48px;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 3;
		display: none;
		position: fixed;
		text-align: center;
		background-color: c(white);
		&--btn {
			right: 20px;
			float: right;
			display: none;
			cursor: pointer;
			position: absolute;
			font-size: 22px;
			padding-top: 13px;
			@media only screen and (max-width: b(md)) {
				display: block;
			}
		}
		&--item {
			cursor: pointer;
			padding: 14px 0;
			display: block;
			font-size: 16px;
			letter-spacing: 1px;
			text-transform: uppercase;
			border-bottom: 1px solid #eee;
			@include ease-transition;
			&:hover {
				background-color: c(aqua);
			}
		}
		&--audience {
			&--item{
				font: {
					family: f(futura);
					size: 18px;
				}
				text-transform: none;
				@include color-modifiers($is_link:true);
				&:nth-child(4) {
					&:hover {
						.header__mobile--audience--item--instituciones {
							color: c(white);
						}
					}
				}
			}
		}
		&__social {
			width: 100%;
			display: block;
			border-bottom: 1px solid #eee;
			@include ease-transition;
			&:hover {
				background-color: #eee;
			}
			&--item {
				cursor: pointer;
				padding: 10px 14px;
				display: inline-block;
				font-size: 18px !important;
			}
		}
	}
}
