.menu-main {
	&__link{
		&-container {
			&:nth-of-type(1) {
				.menu-main__link-decoration {
					top: 'x';
				}
			}
			&:nth-of-type(2) {
				.menu-main__link-decoration {
					top: 'x';
				}
			} 
			&:nth-of-type(3) {
				.menu-main__link-decoration {
					top: 'x';
				}
			}
			&:nth-of-type(4) {
				.menu-main__link-decoration {
					top: 'x';
				}
			}
			&:nth-of-type(5) {
				.menu-main__link-decoration {
					top: 'x';
				}
			}
		}
		&-decoration {
			position: relative;
		}
	}
}