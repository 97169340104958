///
/// Adds background cover with a custom position.
///
/// 
/// @param  {String} position
/// @author Diego Villaseñor
/// @since 1.0.0
/// @content 
////

@mixin bg-cover($position:center){
	background-size: cover;
	-webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
	background-position: $position;
	background-repeat: no-repeat;
}