
/// Absolutetly centers an element inside another that contains it.
/// The element will be centered  "vertically and horizontally", "vertically", or "horizantally"
///
/// Note that container element must have a position property different thatn "static".
///
/// @param  {String} $XYorXorY 	String must be either 'xy' or 'y'. Else an error will be thrown.
@mixin center($XYorXorY){

	@if to-lower-case($XYorXorY) == 'xy' {
	  position:absolute;
	  left: 50%;
    top: 50%;
	  -webkit-transform: translate( -50%, -50% );
	  -moz-transform: translate(-50%, -50%);
	  -ms-transform: translate( -50%, -50% );
    transform:translate( -50%, -50% );
	}@else if to-lower-case($XYorXorY) == 'x' {
	  position:absolute;
	  left: 50%;
    -webkit-transform: translateX(-50% );
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50% );
    transform:translateX(-50% );
	}@else if to-lower-case($XYorXorY) == 'y' {
	  position:absolute;
	  top: 50%;
    -webkit-transform: translateY(-50% );
	-moz-transform: translateY(-50%);
  	-ms-transform: translateY(-50% );
    transform:translateY(-50% );
	}@else{
	  @error 'Argument in mixin "center" must be either string "xy" or string "y" or string "x"';
	}

}
