.conocenos {
	&__map {
		height:calc(100vh - 112px);
		@include under(md) {
			height:calc(100vh - 40px);
		}
		width:100vw;
		&--mt {
			@include under(xs) {
				margin-top: 10%;
			}
		}
	}

	&__box {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 100px;
		@include under (xs) {flex-wrap: wrap; margin-bottom: 0;}

		&--fistst-box {margin-bottom: 0px;}
	}
	&--p {
		@extend %gral-paragraph;
		color:c(white);
		text-align: left;
		text-transform: uppercase;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 10px;
    	max-width: 500px;
		line-height: 30px;
    	//text-align-last: center;
	}
	&--txt {
		@extend %gral-title;
		color: c(white);
		font-weight: bold;
		line-height: 48px;
	}
	&--logo {
		width: 145px;
		margin-bottom: 30px;
	}
	&--link {
		@extend %subtitle;
		padding-top: 0;
		color: c(gray);
		margin-bottom: 30px;
	}
	&--address {
		color: c(purple-alt);
		max-width: 200px;
		text-align: left;
		line-height: 28px;
	}
	&__estadisticas {
		position: relative;
		&--bg-img {
			background-image: url(../../images/bg-gray.svg);
	    		background-size: cover;
	    		width: calc(100% - 40px);
	    		height: 300px;
	    		position: absolute;
	    		top: 50px;
	    		left: 20px;
				z-index: 2;
    		@include under (md) {
    			height: 250px;
    			top: 255;
    		}
		}
		&--box {
			padding: 50px 110px 50px 50px;
			width: 100%;
			max-width: 653px;
    		min-height: 245px;
			@include under(mobile) {
				padding: 32px;
			}
    		&-lg {
    			display: flex;
    			justify-content: flex-end;
    		}
			&-right {
				background-color: c(orange-alt);
				max-width: 500px;
    			margin: 0 0 50px 62%;
    			padding: 30px 165px 0px 50px;
				background-color: c(orange-alt);
	    		@include between (sm, md) {margin-left: 52%;}
	    		@include between (xs, sm) {margin-left: 32%;}
	    		@include under (xs) {margin-left: 0%;}
				&--other-padding {
					max-width: 507px;
					padding: 34px 124px 34px 63px;
					@include under(sm) {
						margin: 0 auto 50px auto !important;
						padding: 34px 50px 34px 124px;
					}
					@include under(xs) {
						max-width: 100%;
					}
					@include under(mobile) {
						padding: 34px;
					}
				}
			}
			&--right-lg {
				background-color: c(purple-alt);
				@include under(xs) {
					max-width: 100%;
				}
				@include under(mobile) {
					padding: 34px;
				}
			}
			&-img {
				width: 100%;
				max-width: 317px;
				height: 301px;
				display: flex;
				align-items: center;
				margin-left: auto;
				margin-right: auto;
				@include under (xs) {margin: 15px 0px;}
			}
			@include color-modifiers ($prop: background-color);
		}
	}
	&__presencia {
		position: relative;

		&--bg-img {
			background-image: url(../../images/bg-gray.svg);
	    		background-size: cover;
	    		width: calc(100% - 40px);
	    		height: 380px;
	    		position: absolute;
	    		top: 80px;
	    		left: 20px;
	    		@include under (md) {
	    			height: 250px;
	    			top: 334px;
	    		}
		}

		&-container {
		    max-width: 860px;
			@include over(md) {

				position: relative;
				top: -130px;
			}
		}

		&--box {
			padding: 20px 10px;
			background-color: #4291CC;
			margin-right: auto;
			margin-left: auto;
			max-width: 860px;
			width: 100%;
			@include under(md) {
				width: calc(100% - 50px);
			}
			padding: 50px 20px 50px;
		}
		&--list {
			display: flex;
			flex-wrap: wrap;
			&-item {
				@extend %paragraph;
				font-size: 18px;
				flex-basis: 50%;
				@include under (xs){
					font-size: 16px;
				}
			}
		}
		&--li {
			list-style-type: disc;
			list-style-position: inside;
		}
		&--button {
			display: flex;
    			justify-content: center;
	    		margin-top: 20px;
		}
		&--submit {
			font-family: f(daxline) !important;
			font-size: 13px;
			&:hover {
				background-color: black;
				color: c(white);
			}
		}
	}
	&__ubicacion {
		@extend %subtitle;
		position: relative;
		background-color: c(row-bgc);
		font-size: 16px;
		text-align: center;
  		padding-bottom: 50px;

		&-container {
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;
			max-width: 860px;
		}
	}

	&__info-box {
		&:empty {
			display: none;
		}
		position: absolute;
		top: 0;
		left: 0;
		height: auto;
		max-height: 100%;
		overflow: auto;
		width: 300px;
		@include under(md) {
			width: 150px;
		}
		@include under(contact-sm) {
			width: 120px;
		}
		padding: 20px 10px;
		z-index: 1;
		text-align: center;
		background-color: transparentize(c(white), 0.1);
		&-ttl {
			font-family: f(daxline);
			font-size: 30px;
			margin-bottom: s(2);
			@include under(md) {
				font-size: 26px;
			}
			@include under(contact-sm) {
				font-size: 15px;
				margin-bottom: s();
			}
		}
		&-subttl {
			font-family: f(futura);
			font-size: 26px;
			margin-bottom: s();
			@include under(md) {font-size: 20px;}
			@include under(contact-sm) {
				font-size: 16px;
				margin-bottom: s(1/2);
			}

			&--cuates {
				color: c(purple);
			}
			&--eventos{
				color: c(orange);
			}
			&--empresas{
				color: c(blue);
			}
			&--instituciones-aliadas{
				color: c(yellow);
			}
			&--universidades{
				color: c(aqua);
			}
			&--voluntarios{
				color: c(orange-alt);
			}
		}

		&-p {
			font-family: f(futura);
			font-size: 40px;
			margin-bottom: s(3/2);
			@include under(md) {font-size: 30px;}
			@include under(contact-sm) {
				font-size: 18px;
				margin-bottom: s();
			}
		}
	}
}
