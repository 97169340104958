/*====== patch#1 Cambiamos el color de los botones amarillos=====
|
| Lo hacemos aquí puesto que esta petición trata de un cambio
| muy específico respecto del color de los botones y no del color amarillo en general​,
| y porque que afecta una función muy general que da los colores a gran parte del sitio,
| Por ello hacemos esto, para evitar el riesgo de roper algo o tener que hace muchísimos
| cambios a través de un número indeterminado de archivos
|
*/
.portada-audience--yellow .portada-audience__button,
.section__button--universidades {
	border-color: #FED01C;
	background-color: #FED01C;
}



/*====== patch#2 .[commit [8c130c2]:  grid__row background color: white y a veces .grid__container=============
| Ajustamos el background color del grid__row
| para efecto imagen de fondo estática de la parte superior
| de Home y de algunas otras páginas.
| En algunos casos es necesario también aplicarlo a los contenedores :/
| Y agregamos un fix para hacerlos transparente cuando sea necesario.
 */
.grid__row {
	background-color: c(row-bgc);
	&.transparent {
		background-color: transparent !important;
	}
}

.grid__container {
	&.white {
		background-color: c(row-bgc);
	}
}

.other-font-size {
	font-size: 28px !important;
}
