///
/// An "@media" mixin that responds to a  min and max screen width.
///
/// 
/// @param  map key or string $min   Map with the names of breaks and their units or only the desired unit or the Map key.
/// @param  map key or string $max   Map with the names of breaks and their units or only the desired unit or the Map key.
/// @author Diego Villaseñor
/// @since 1.0.0
/// @content 
////
@mixin between($min, $max) {
  @if map_has_key($breaks, $max) {
    $max: map_get($breaks, $max);
  }
  @if map_has_key($breaks, $min) {
    $min:  map_get($breaks, $min);
  }
  
  @media only screen and (min-width: $min) and (max-width: $max) {
    @content;
  }

}
