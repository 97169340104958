

///
/// Defines the border radius of en element
/// @param  {Number} $radius 
///@author Roberto Palomino
///@group ultraligero legacy
///
@mixin border-radius( $radius ){
	border-radius: $radius;
		-moz-border-radius: $radius;
		-webkit-border-radius: $radius;
		-khtml-border-radius: $radius;
}

///
/// Ajuste para el tamaño de las cajas con contenido interno
/// @param  {Number} $radius 
/// @group ultraligero legacy
/// @author Roberto Palomino
@mixin border-sizing($box) {
	box-sizing: $box;
		-webkit-box-sizing: $box;
		-moz-box-sizing: $box;
}


///  Especificar la fuentes personalizada para el diseño de la pagina
///	
/// @param {String} $font-family: nombre de la fuente
/// @param {String} $font-filename: nombre del origen (archivo) que contiene la fuente
/// @param {String}|number $font-weight: alto de la fuente. 
/// @param {String} $font-style: estilo de la fuente. 
/// @param {String} $font-stretch: anchura de la fuente. 
/// @group ultraligero legacy
/// @author Roberto Palomino


@mixin declara-font-face($font-family, $font-filename, $font-weight: normal, $font-style: normal, $font-stretch: normal) {
	@font-face {
		font-family: '#{$font-family}';
		src: url('#{$font-filename}.eot');
    	src: url('#{$font-filename}.eot?#iefix') format('embedded-opentype');
		src: url('#{$font-filename}.woff') format('woff');
        src: url('#{$font-filename}.ttf') format('truetype');
        src: url('#{$font-filename}.svg##{$font-filename}') format('svg');
		font-weight: $font-weight;
		font-style: $font-style;
		font-stretch: $font-stretch;
	}
}



///
///  imagen de fondo
/// 
/// @param {string} $file: nombre del origen (archivo) de la imagen
/// @param {string} $type: formato (extensión) de la imagen
/// @param {number} $width: Ancho de la imagen
/// @param {number} $height: Alto de la imagen
/// @group ultraligero legacy
/// @author Roberto Palomino
@mixin background-image-retina($file, $type, $width, $height) {
	background-image: url($file + '.' + $type);

	@media (-webkit-min-device-pixel-ratio: 2), (-moz-min-device-pixel-ratio: 2) {
		& {
			background-image: url($file + '@2x.' + $type);
			-webkit-background-size: $width $height;
		}
	}
}

/// atenuación del color de fondo
/// @param {string} $color
/// @param {string} $element
/// @param {string} $property
/// @group ultraligero legacy
/// @author Roberto Palomino
@mixin link-claro($color, $element: a, $property: background-color) {
	#{$element} { #{$property}: lighten($color, 7%);
		&:hover { #{$property}: lighten($color, 14%) };
	}
}

/// ajuste de la transparencia 
/// @param {number} $opacity
/// @group ultraligero legacy
/// @author Roberto Palomino
@mixin opacidad($opacidad){
	-moz-opacity: $opacidad;
	-khtml-opacity: $opacidad;
	opacity: $opacidad;
	-ms-filter:'"progid:DXImageTransform.Microsoft.Alpha" (Opacity='+$opacidad+')';
	filter: progid:DXImageTransform.Microsoft.Alpha(opacity=$opacidad);
	filter:alpha(opacity=$opacidad);
}
