.login {
	padding: 85px 0;
	text-align: center;
	&__links {
		&--link {
			margin: 0 15px;
			display: inline-block;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	&--title {
		font-size: 22px;
		text-align: center;
		margin-bottom: 75px;
		text-transform: uppercase;
		@include ease-transition;
		@media (max-width: b(md)) {
			margin-bottom: 50px;
		}
	}
	&__form {
		width: 100%;
		margin: 0 auto 30px auto;
		max-width: 300px;
		&--input {
			width: 100%;
			padding: 8px 5px 0px 5px;
			display: inline-block;
			font-size: 18px;
			text-align: center;
			background: c(white);
			margin-bottom: 45px;
			vertical-align: middle;
			@include ease-transition;
			&:focus {
				background: #eee;
			}
		}
		&--submit {
			@extend %button;
			@include ease-transition;
			&:hover {
				background-color: black !important;
				color: c(white);
			}
		}
	}
}
