
/// Sets the color for a plahceholder
///
/// @param {string} $color
/// @since 1.0.0
/// @author Diego Villaseñor
/// @todo Add @content capabilities

@mixin placeholder($placeholdercolor){
	::-webkit-input-placeholder {
		color: $placeholdercolor;
		@content;
	}
	:-moz-placeholder {
		color: $placeholdercolor;
		@content;
	}/// Firefox 18-
	::-moz-placeholder {
		color: $placeholdercolor;
		@content;
	}///Firefox 19+
	:-ms-input-placeholder {
		color: $placeholdercolor;
		@content;
	}
}
