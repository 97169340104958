//Portadas que no son adminisrables, background texturas.
@mixin portada-audience($color) {

	.portada-audience--#{$color} {
		background-color: c(#{$color}-light);
		.portada-audience__bg-img {
			background-image: url('../../images/bg-#{$color}.svg');
			background-size: cover;
			background-attachment: fixed;
			width: 100%;
		}
		.portada-audience__box {
			background-color: c(white);
			margin: 120px 0;
			padding: 50px;
			opacity: 0;
			@include under (xs){
				padding: 42px 17px;
			}
		}
		.portada-audience__p {
			@extend %paragraph-portada;
			//color: c(#{$color}-alt);
		}
		.portada-audience__sbttl {
			font: {
				family: f(daxline);
				weight: 200;
				size: 13px;
			}
			text-align: center;
			text-transform: uppercase;
			padding-top: 35px;
		}

		.portada-audience__menu-programas {
			display: flex;
			justify-content: space-around;
			padding-top: 35px;
			@include under (sm) {
				flex-direction: column;
				align-items: center;
			}
			&--item {
				max-height: 30px;
				min-height: 30px;
				@include under (sm) {margin-top: 10px;}
				font-family: f(daxline) !important;
				font-size: 13px;
				&:hover {
					background-color: c($color);
					color: c(white);
				}
			}
		}
		.portada-audience__button {
			@extend %button;
			display: flex;
			justify-content: center;
			background-color: c($color);
			border: c($color);
			color: c(white);
			font-family: f(daxline) !important;
			font-size: 13px;
			@include under (sm) {margin-top: 10px;}
			&:hover {
				color: c(white);
				background-color: black;
			}
			&--cuates {
				@extend .portada-audience__button;
				margin: 25px auto;
			}
		}
	}
	.contact__submit--#{$color} {
		&:hover {
			background-color: c($color) !important;
			border: c($color) !important;
			color: c(white) !important;
		}
	}
}


//Portadas administrables, background image.
.portada-pages {
	&--bg-img {
		&-transparent-container {
			height: 340px;
			background-color: transparent;
		}
		position: fixed;
		height: 340px;
		top: 150px;
		@include under(sm) {
			top: 39px;
		}
		width: 100%;
		@include bg-cover(center);
	}
	&--box {
		margin: 120px 0;
		padding: 50px;
		background-color: rgba(96, 92, 129, 0.85);
		@include under (xs){
				padding: 42px 17px;
			}
		}
	&--p {
		color: c(white);
	}
}
