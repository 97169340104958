$colors: (
	base:	      #0F0708,
	dark:	      #222222,
	white:	      #FFFFFF,
	row-bgc: 	#FFFFFF,
	gray:		  #808080,
	gray-light:	  #DCDCDC,		
	aqua:    	  #8ED1C4,
	aqua-alt:     #60BB9A,
	aqua-hover:   lighten(#8ED1C4, 15%),
	aqua-superlight:   transparentize(#8ED1C4, 0.8),
	aqua-light:   #B5E2D4,
	orange:  	  #FF9566,
	orange-hover:   lighten(#FF9566, 15%),
	orange-alt:   #F47735,
	orange-light: #EB9969,
	yellow:  	  #E5BE1C,
	yellow-hover:   lighten(#E5BE1C, 15%),
	yellow-light: #F4E09F,
	yellow-alt:   #DBB309,
	blue:    	  #6BAAD5,
	blue-hover:   lighten(#6BAAD5, 15%),
	blue-alt:     #4298D3,
	blue-light:   #A6CCE6,
	purple:	  	  #9A9CCD,
	purple-hover:   lighten(#9A9CCD, 15%),
	purple-alt:   #605C8E,
	purple-light: #E0E0E8,
);

@function c($color:base) {
	@return map-get($colors, $color);
}

$colorForeground: c();
$colorBackground: c(white);
$colorFeatured: c(dark);
$colorError: #8A1010;