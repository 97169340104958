.set {
	padding: 85px 0;
	text-align: center;
	&--title {
		font-size: 22px;
		text-align: center;
		margin-bottom: 75px;
		text-transform: uppercase;
		@include ease-transition;
		@media (max-width: b(md)) {
			margin-bottom: 50px;
		}
	}
	&__form {
		width: 100%;
		margin: 0 auto 30px auto;
		max-width: 300px;
		&--input {
			width: 100%;
			padding: 8px 5px 0px 5px;
			display: inline-block;
			font-size: 18px;
			text-align: center;
			background: c(white);
			margin-bottom: 45px;
			vertical-align: middle;
			@include ease-transition;
			&:focus {
				background: #eee;
			}
		}
		&--submit {
			width: auto;
			color: c(white);
			border: none;
			cursor: pointer;
			display: inline-block;
			padding: 15px 30px 8px 30px;
			font-size: 18px;
			background: c(aqua);
			text-align: center;
			text-transform: uppercase;
			white-space: nowrap;
			@include ease-transition;
			&:hover {
				padding: 15px 25px 8px 25px;
			}
		}
	}
}