%input-base {
	color: c();
	font-family: f(futura);		
	font-weight: normal;
	font-size: 16px;
}

%input-arrow {
	@include center('y');
	display: block;
	left: 0;
}
.input {
	&__label {
		@extend %input-base;
		width: 100%;
		display: block;
		line-height: 1.1;
		margin-bottom: 10px;

	}
	&__text {
		@extend %input-base;
		font-size: 17px;
		//text-transform: uppercase;
		height: 28px;
		min-width: 100%;
		padding: 0 20px;
		border-color: transparent;
		border-bottom-color: c() ;
		background-color: c(gray-light);
		@include color-modifiers;
		&-container {
			position: relative;
			margin-bottom: 24px;
		}
		&-arrow{
			top:60%;
			left: 5px;
			position: absolute;
		}
	}
	&__select {
		border-color: c(white);
		background-color: transparent;
		color: c(purple); 
		font-family: f(daxline);
		font-weight: normal;
		font-size: 18px;
		text-align-last: center;
		text-transform: uppercase;
		width: 100%;
		z-index: 1;
		&-arrow {
			top: 33px;
    		position: absolute;
    		right: 225px;
			&--rotate {
				//rota el angle bracket
				// Safari
				-webkit-transform: rotate(90deg);
				// Firefox
				-moz-transform: rotate(90deg);
				// IE
				-ms-transform: rotate(90deg);
				// Opera
				-o-transform: rotate(90deg);
				// Internet Explorer
				filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
			}
		}
	&--options {
			@exten %gral-paragraph;
			color: c(purple);
			text-transform: uppercase;
	}
}
	&__submit {
		@extend %button;
		padding: 8px;
	}
}