%link,
.link {
	color: c(white);
	cursor: pointer;
	font: {
		family: f(daxline);
		size: 13px;
	}
	line-height:100%;
	text-align: center;
	text-transform: uppercase;
}
%link-secondary,
.link-secondary {
	cursor: pointer;
	font: {
		family: f(futura);
		size: 18px;
	}
}
