//_________________________ Config:Fuentes _________________________ \\

/**
 * Declare fonts using the Mixin
 */

// @include declara-font-face(FuturaBook,     '../fonts/FuturaStd-Book', normal);
// @include declara-font-face(FuturaBold,     '../fonts/FuturaStd-Bold', bold);
// @include declara-font-face(DaxlineRegular, '../fonts/DaxlinePro-Regular', normal);
// @include declara-font-face(DaxlineBlack,   '../fonts/DaxlinePro-Black', bold);

@mixin declara-font-face2($font-family, $font-filename, $font-weight: normal, $font-style: normal, $font-stretch: normal) {
	@font-face {
		font-family: '#{$font-family}';
		src: url('#{$font-filename}.eot');
    	src: url('#{$font-filename}.eot?#iefix') format('embedded-opentype');
        src: url('#{$font-filename}.otf') format('opentype');
        src: url('#{$font-filename}.svg##{$font-filename}') format('svg');
		src: url('#{$font-filename}.ttf') format('truetype');
		src: url('#{$font-filename}.woff2') format('woff2');
        src: url('#{$font-filename}.woff') format('woff');
		font-weight: $font-weight;
		font-style: $font-style;
		font-stretch: $font-stretch;
	}
}


@include declara-font-face2(Futura, '../fonts/FuturaStd-Book', normal);
@include declara-font-face2(Futura, '../fonts/FuturaStd-Bold', bold);
@include declara-font-face2(Daxline, '../fonts/DaxlinePro-Black', bold);
@include declara-font-face2(Daxline, '../fonts/DaxlinePro-Regular', normal);
/// Refer to font families through mapped variables.
/// Font families must be written as lists, that is, between parenthesis.

$fonts:(
	futura: ('Futura', sans-serif),
	daxline:('Daxline', sans-serif),
);

@function f($font) {
	@return map-get($fonts, $font);
}
