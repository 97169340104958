.empresas {
	&__box {
		padding: 20px 20px 50px 20px;
		width: calc(100% / 3);
    	margin-bottom: 50px;
		@include under(meta) {
			width: calc(100% / 2);
		}
		@include under(contact-sm) {
			width: calc(100%);
		}
		& > a  {
			&:hover {
				.empresas__donadores--ttl {
					color: c(purple-alt);
				}
			}
		}
	}
	&__donadores {
		&--ttl {
			@extend %subtitle;
			color: c(gray);
			padding: 20px;
			text-align: center;
			&:hover {
				color: c(purple-alt);
			}
		}
		&--img {
			width: 260px;
			height: 130px;
			display: flex;
    		align-items: center;
			margin-bottom: 15px;
			margin-left: auto;
			margin-right: auto;
		}
	}
}
