///Imprime los modificadores de una clase a partir del $color-modifiers-map. 
///@param $prop [color o background-color]
///@param $is_link [true, false] da los link states
///
///@example
/// para $color-modifiers-map: (cuates: purple);
/// y .link {@include color-modifiers;}
/// imprime .link--cuates{color:/*purple color del $colors map*/}
@mixin color-modifiers($prop: color, $is_link: false) {
	$color-modifiers-map: (//el mapa recibe <nombre de la clase>:<string de color> en el mapa $colors
		cuates: purple,
		cuates-alt: purple-alt, 
		voluntarios: orange,
		voluntarios-alt: orange-alt,
		empresas: blue,
		empresas-alt: blue-alt,
		instituciones: aqua,
		instituciones-alt: aqua-alt,
		universidades: yellow,
		universidades-alt: yellow-alt
	);
	@each $class, $color in $color-modifiers-map {
		&--#{$class} {
			#{$prop}: c($color);
			@if($is_link) {
				@include link-states {	
					#{$prop}: c(#{$color}-light);
					#{$prop}: c(#{$color}-alt);
				}
			}
		}
	}
}

@import 'components/_portada.scss';
@import 'components/_section-audience.scss';