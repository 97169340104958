/// Crea
/// @param {unit} $static_col_width 
/// @param {string} $static_col_name nombre opcional
/// @param {string} $calc_col_name nombre opcional
@mixin flex-static-col-with-adaptable-cols($static_col_width, $static_col_name: 'static', $calc_col_name: 'flex') {
	&--#{$static_col_name} {
		flex: 0 0 $static_col_width;
	}
	&--#{$calc_col_name} {
		flex-grow: 1;
		width: 100%;
	}
}