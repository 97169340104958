.blog {
	&__col-1-3 {
		@include over(cuadritos-home) {
			&:nth-of-type(1),
			&:nth-of-type(2),
			&:nth-of-type(3) {
				.home__box { border-top: none;}
			}
		}
		@include under(cuadritos-home) {
			@include ternary-column-padding($reset: true);
			width: 100%;
			&:nth-of-type(1) { .home__box { border-top: none;}}
		}
	}
	&--ttl {
		@extend %title-blog;
	}
	&--categorias {
		@extend %subtitle;
		color: c(aqua-alt);
		padding-bottom: 50px;
	}
	&--p {
		@extend %paragraph-blog;
		padding-bottom: 50px;
	}
	&--link {
		@extend .blog--categorias;
		text-transform: uppercase;
		text-decoration: underline;
		padding-top: 20%;
	}
}

.blog-single {
	&--col-sm {
		flex-grow: 1;
		width: 100%;
		max-width: 180px;
		margin-left: 170px;
		padding-right: 35px;
		@include under (1119px) {
			margin-left: 10px;
		}
	}
	&--col-lg {
		padding-top: 50px;
	    width: 100%;
	    min-height: 100px;
	    max-width: 850px;
	    flex: 0 1 auto;
	}
	&--col-video {
		display: flex;
		align-items: center;
		max-width: 700px;
	}
	&__box {
		margin-left: 300px;
	}
	&__search {
		max-width: 315px;
		margin-left: 215px;
		border: 0px;
		background-color: c(gray-light);
		&--txt {
			color: c(white);
			font: {
				family: f(futura);
				size: 12px;
			}
			position: absolute;
    		right: 275px;
    		top: 6px;
		}
	}
	&__sidebar {
		color: c(gray);
		text-align: right;
		font: {
			family: f(daxline);
			size: 13px;
		}
		&--credits {
			line-height: 20px;
		}
	}
	&__social {
		&--item {
			font-size: 16px !important;
			padding: 15px
		}
	}
	&--ttl {
		@extend %title-blog;
		font-size: 36px;

	}
	&--quote {
		@extend %paragraph-quote;
		text-align: left;
	}
}