%paragraph {
    color: c();
    font-family: f(futura);
    font-weight: normal;
    font-size: 20px;
    line-height: (5/3);
}

%gral-paragraph {
    font-family: f(daxline);
    font-size: 18px;
}

%paragraph-quote,
.paragraph-quote, {
    font-family: c(daxline);
    font-weight: 200;
    font-size: 19px;
    text-transform: uppercase;
    line-height: 30px;
}

%paragraph-portada,
.paragraph-portada {
    @extend %paragraph;
    font-size: 24px;
    text-align: center;
	@include under(mobile) {
		font-size: 18px;
	}
}

%paragraph-blog,
.paragraph-blog {
    font: {
        family: f(futura);
        size: 20px;
    }
}
