.section {
	margin-bottom: 130px;
	padding-top: 180px;
	&--no-pt {
		padding-top: 0px;
	}
	&--pt90 {
		padding-top: 90px;
	}
	&__box {
		&--quote {
		    width: 50%;
			@include under(sm) {
				margin-bottom: 10%;
			}
			@include under (xs) {
				position: relative;
				width: 80%;
			}
		}
	}
	&__ttl {
		@extend %title;
		line-height: 58px;
		padding-top: 0;
		position: relative;
		top: -7px;//hacemos esto para poder alinear perfectamente las tipografías de este título y del titulo de .section__ttl--quote
		padding-bottom: 50px - 7px;//del top que le quitamos
		&--quote {
			color: c(gray);
			text-transform: uppercase;
			font: {
				family: f(futura);
				size: 25px;
				weight: bold;
			}
		}
	}
	&__sbttl {
		@extend %title;
		font-size: 25px;
		&--quote {
			color: c(gray);
			font: {
				family: f(daxline);
				size: 18px;
				weight: 100;
			}
			line-height: 30px;
		}
	}
	&__p {
		@extend %paragraph;
		padding-bottom: 20px;
	}
	&__button {
		@extend %button;
		color: c(white);
		font-family: f(daxline) !important;
		font-size: 13px;
		@include color-modifiers ($prop: background-color);
		@include color-modifiers ($prop:border-color);
		&:hover {
			@include color-modifiers ($prop:color);
			background-color: black;
		}
	}
	&__benefits {
		padding-top: 100px;
		@include under(sm) {
			width: 100%;
			max-width: 230px;
			margin-right: auto;
		}
		&--ttl {
			font: {
				family: f(futura);
				size: 25px;
				weight: bold;
			}
			color: c(aqua-alt);
			padding-bottom: 10px;
		}
		&--li {
			@extend %paragraph;
			font-family:f(daxline);
			color: c(aqua-alt);
			list-style-type: disc;
			list-style-position: inside;
			text-transform: uppercase;
			font-size: 18px;
		}
	}
	&--caso-exito {
		background-color: c(gray-light);
		padding: 100px 0;
		&-ttl {
			@extend %title;
    		@include over (md) {
    			position: absolute;
    			bottom: 45px;
    		}
		}
		&-divisor {
			border-bottom: 1px solid c(purple);
			padding: 50px 0 10px;
		}
		&-caption {
			color: c(purple-alt);
			font: {
				family: f(futura);
				size: 20px;
			}
			padding: 50px 0;
			line-height: 32px;
		}
		&-blog-link {
			color: c(purple-alt);
			font: {
				family: f(futura);
				size: 14px;
			}
			text: {
				transform: uppercase;
				decoration: underline;
			}
		}
		&-quote {
			@extend %paragraph-quote;
			font-family: f(daxline);
			font-size: 18px;
			line-height: 30px;
			text-align: left;
			padding-top: 80px;
		}
	}
	&__subtitled-divisor {
		position: relative;
		padding-bottom: 60px;
		background-color: c(row-bgc);
		&--col {
			margin-left: 170px;
			flex-grow: 1;
			width: 100%;
			max-width: 180px;
			@include under (1119px) {
				margin-left: 10px !important;
			}
			&--other-ml {
				margin-left: 210px;
			}
			&--us {
				max-width: 100px;
			}
		}
		&--col-line {
			padding-top: 50px;
    		width: 100%;
    		min-height: 100px;
    		flex: 0 1 auto;
			@include make-divisor(c(gray), 1px);

		}
		&--sbttl {
			@extend %subtitle;
			color: c(gray);
			position: absolute;
    			bottom: -6px;
    		}
	}

	&__images-mosaic {
		&-container {
			display: flex;
			flex-wrap:wrap;
		}
		&-col {
			@include bg-cover(center);
			width: calc(100% / 3);
			height: auto;
			padding-bottom: 25%;
			@include under(md) {
				width: calc(100% / 2);
				padding-bottom: 40%;
			}
			@include under(sm) {
				width: 100%;
				padding-bottom: 100%;
			}
		}

		&-img {
			width: 100%;
			height: auto;
			vertical-align: middle;
		}
	}
}
