@include reset;

/**
* Basic Styles
* ---------------
* Edítalos o sobreescríbelos
*
*/



/**
 * Super basic stylings
 *
 */
* {
	@include border-sizing(border-box);
}

html {
	height: 100%;
}

html, body {min-height: 100%;}

body {
	position: relative;
	text-rendering: optimizeLegibility;  // mejor legibilidad
	-webkit-font-smoothing: antialiased; // Hace la fuente más bonita
	font-size: 14px;
	font-family: BlissPro;
}

strong{font-weight: bold;}


img {-ms-interpolation-mode: bicubic;}
.column, .columns {
	img{max-width:100%;}
}

img {
	display: block;
	max-width: 100%;
	width: 100%;
}

p {
	em{font-style: italic;}
}

section, article, aside, header, footer, nav, figure, figcaption{
   display: block;
}
time, mark {
    display: inline-block;
}
