html,body {
	color:c(base);
	font-family: f(futura);
}
body {
	@include under(md) {
		padding-top: 40px !important; 
	}
}
.wrap {
	width: 100%;
	max-width: 1196px;
	margin: 0 auto;
	position: relative;
    padding: 0 60px;
}

//Estilos para wysiwyg

.content {
	ul li {
        list-style-type: disc;
        margin-left: 40px;
    }

    ol li {
        list-style-type:decimal;
		margin-left: 40px,
    }

    b {
       font-weight: bold;
    }
    i {
        font-style: italic;
    }

    sub, sup {
    	font-size: 75%;
    	line-height: 0;
    	position: relative;
    	vertical-align: baseline;
    }

    sub {
    	bottom: -0.25em;
	}

	sup {
    	top: -0.5em;
	}	
}