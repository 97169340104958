.event {
	padding: 30px 0 60px 0;
	background-color: c(purple);

	&--row {
		background-color: transparent !important;
	}

	&__header {
		width: 100%;

		&--dates {
			@extend .subtitle;
			color: c(white);
		}
		&--ttl {
			@extend %title-blog;
			color: c(white);
			max-width: 320px;
			line-height: 38px;
			padding-top: 30px;
		}
		&--target {
			font-family: f(daxline);
			font-size: 18px;
			color: c(white);
			max-width: 320px;
			line-height: 30px;
			padding-top: 20px;
			text-transform: uppercase;
		}
	}

	&__box {
		width: 330px;
		padding-right: 20px;
		@include under(sm) {
			width: 100%;
		}

		&--container {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}
	}

	&__content {
		width: 100%;
		max-width: 460px;
		padding-top: 30px;
		@include under(sm) {
			max-width: 100%;
		}

		&--description {
			@extend %paragraph;
			color: c(white);
		}
		&--pagination {

		}
	}
}
