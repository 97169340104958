%divisor{
	width: 100%;
	border-bottom: 1px solid c();
}

.divisor{
	width: 100%;
	border-bottom: 1px solid c();
}

@mixin make-divisor($color, $height: 3px) {
	@extend %divisor;
	border-bottom: $height solid $color;
}