.faq {
	&__box {
		margin: 120px 0px 120px 100px;
		@include under (xs){
			margin: 50px 0px 120px 0px;
		}
	}
	&__ttl {
		@extend %gral-title;
		padding-top: 20px;
		font-weight: bold;
		@include over (xs){
			text-align: right;
		}
	}
	&__sbttl {
		@extend %title-blog;
		padding-top: 60px;
		@extend .flipIn
	}
	&__accordeon--trigger {
		color: c(aqua-alt);
    	cursor: pointer;
    	padding-top: 20px;
    	font-size: 18px;
    	line-height: 150%;
    	margin-bottom: 20px;
    	text-transform: uppercase;
		text-decoration: underline;

      @extend .no-select;
	}
	&__content {
		@extend %paragraph;
		position: relative;
  	max-height: 800px;

    @extend .transition;
		overflow: hidden;
		opacity: 1;
 		transform: translate( 0 , 0 );
  	z-index: 2;
	}
}

// Accordeon

.transition {
  transition: all 1s ease-in-out;
}

.flipIn {
  animation: flipdown 0s ease both;
}

.no-select {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.faq__accordeon {
   li {
    position: relative;
    @extend .flipIn;

    &:nth-of-type(1) {
      animation-delay: 0.5s;
    }

    &:nth-of-type(2) {
      animation-delay: 0.75s;
    }

    &:nth-of-type(3) {
      animation-delay: 1s;
    }

    &:last-of-type {
      padding-bottom: 0;
    }


    input[type=checkbox] {
      position: absolute;
      cursor: pointer;
      width: 100%;
      height: 100%;
      z-index: 3;
      opacity: 0;

      &:checked {
        & ~ .faq__content {
          margin-top: 0;
          max-height: 0;
          opacity: 0;
          transform: translate(0, 50%);
        }
		& ~ .return{
			display: none;
		}
		& ~ .read {
			display: block;
		}
      }
    }
  }
  .return{
	  display: block;
  }
  .read {
	  display: none;
  }
}

@keyframes flipdown {
  0% {
    opacity: 0;
    transform-origin: top center;
    transform: rotateX(-90deg);
  }

  5% {
    opacity: 1;
  }

  80% {
    transform: rotateX(8deg);
  }

  83% {
    transform: rotateX(6deg);
  }

  92% {
    transform: rotateX(-3deg);
  }

  100% {
    transform-origin: top center;
    transform: rotateX(0deg);
  }
}
