%button {
	display: inline-block;
	border:1px solid c();
	padding: 9px 0;
	max-width: 180px;
	width: 100%;
	text-align: center;
	background-color: c(white); //!important;
	cursor: pointer;
	text-transform: uppercase;
	font { 
		family: f(daxline);
		size: 12px;
		weight: normal;
	}
	transition: all 0.3s ease;
 	@include link-states {
		background-color: transparent; //!important;
 		border-color: transparent;
 		color: c(orange-base);
 	}
}
