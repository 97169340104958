.home {
	$quotes: 620px;

	&__cover-bg {
		background-size: cover;
		background-repeat: no-repeat;
		min-height: 628px;
		background-attachment: fixed;
		width: 100%;
		background-position: center;
	}
	&__col-1-3--blog {
		@include over(cuadritos-home) {
			&:nth-of-type(1),
			&:nth-of-type(2),
			&:nth-of-type(3) {
				.home__box { border-top: none;}
			}
		}
		@include under(cuadritos-home) {//usamos este break casi solo porque sí
			@include ternary-column-padding($reset: true);
			width: 100%;
			&:nth-of-type(1) { .home__box { border-top: none;}}
		}
	}
	&__box {
		border-top: c(gray-light) solid 1px;
	}
	&__quote {
		font: {
				family: f(daxline);
				size: 19px;
				weight: 100;
			}
		text-transform: uppercase;
	}
	&__sbttl {
		color: c(gray);
		font: {
			family: f(futura);
			size: 18px;
		}
	}
	&__audience {
		padding: 20px;
		//comienza parche para bgc blanco... ver _patches.scss patch#2 para más detalles
		position: relative;
		padding-top: 120px;
		padding-bottom: 120px;
		margin-bottom: 0;
		background-color: c(row-bgc);
		//termina parche
		&--bg-img {
			background-image: url('../../images/bg-gray.svg');
			background-size: cover;
			width: calc(100% - 40px);
    		height: 620px;
    		position: absolute;
			z-index: 2;
			top: 25%;
    		left: 20px;
			@include under(meta) {
				width: 100%;
				left: 0px;
				right: 0px;
			}
    		@include under (sm) { //Mantener la posicion sin hacer un cambio para cada breake
    			height: 100%;
    			top: 0;
    		}
		}
		&--box {
			&--cuates {
				max-width: 820px;
				margin: 0 auto 20px;
				padding-right: 166px;
				@include under(sm) {
					padding-right: 42px;
				}
				@include under (xs) {
					margin: 20px auto;
					//padding-right: 0px;
				}
			}
			@include color-modifiers ($prop: background-color);
		}
		// styles
		&--sbttl {
			color: c(white);
			font: {
				family: f(futura);
				size: 19px;
			}
		}
		&--p {
			color: c(white);
			
			font: {
				family: f(daxline);
				size: 13px;
			}
			
			padding-top:32px;
			height: 100px;
			overflow: auto;
			line-height: 21px;
			text-transform: uppercase;
			
			&--other-padding {
				padding-top: 6px;
				line-height: 20px;
			}

			&.fs-16 {
				font-size: 16px;
			}
		}
		&--divisor {
			width: 31px;
			height: 20px;
			@include make-divisor(c(white), 1px);
		}
	}
	&__quote-block {
		padding-top: 40px;
		//comienza parche para bgc blanco... ver _patches.scss patch#2 para más detalles
		position: relative;
		background-color: c(row-bgc);
		//termina parche
		&--box {
			display: flex;
			align-items: center;
			@include under (xs) {
    			flex-wrap: wrap;
    			justify-content: center;
    		}
			&--justify {
				justify-content: space-between;
				flex-direction: row;
				flex-wrap: wrap;
				max-width: 820px;
			    width: 100%;
				@include under(sm) {
					flex-wrap: nowrap;
					margin-bottom: 55px;
				}
				@include under(mobile) {
					flex-wrap: wrap;
				}
			}
		}
		&--txt-box {
			background-color: c(gray-light);
			min-height: 170px;
			padding: 20px;
			width: 360px;
    		position: absolute;
    		top: 50px;
    		right: 10px;
			&--other-width {
				width: 100%;
				max-width: 502px;
				@include under($quotes) {
					position: relative !important;
					top: 0px;
					margin-top: 15px;
					width: 100% !important;
					max-width: 580px !important;
				}
			}
    		@include under (md) {
    			width: 318px;
    			position: absolute;
    			right: 0px;
    		}
    		@include under (mobile) {
    			top: 20px;
    			width: 100%;
    			position: relative;
    		}
		}
		&--img-left {
			display: flex;
    		align-items: center;
			@include under (mobile) {min-width: 100%;}
			// & img {
			// 	    max-height: 280px;
			// }
		}
		&--img-right {
			width: 50%;
			border-radius: 50%;
			margin: 0 85px;
			background-size: cover;
			min-width: 20rem;
			&--other-width {
				width: 100%;
				max-width: 191px;
				margin: 0px;
				min-width: 0;
				@include under(mobile) {
					margin-top: 25px !important;
					margin-left: auto !important;
					margin-right: auto !important;
				}
			}
			@include under (sm) {margin:0;}
			//@include under(cuadritos-home){min-width:15rem;}

			&:after {
				content: "";
				padding-bottom: 100%;
				display: block;
			}
		}
		&--p {
			@extend %subtitle;
			color: c(gray);
			text-transform: none;
			&--align-text {
				text-align: right;
    			padding-right: 42px;
			}
			&--our-friend {
				text-align: right;
				padding-right: 135px;
				font-size: 18px;
				line-height: 30px;
				@include under(md) {
					padding-right: 0;
				}
			}
		}
		&--q {
			@extend %paragraph-quote;
			text-align: right;
			line-height: 28px;
			padding-right: 50px;
			min-width: 20rem;
			&--align-text {
				width: 100%;
				max-width: 484px;
				text-align: initial;
				padding-right: 0;
				@include under(sm) {
					max-width: calc(100% - 200px);
				}
				@include under(mobile) {
					max-width: 100%;
				}
			}
			& > q {
				font-family: f(daxline);
				font-size: 18px;
				line-height: 30px;
			}
			&--our-friend {
				padding-right: 135px;
				font-family: f(daxline);
				font-size: 18px;
				line-height: 30px;
				@include under(md) {
					padding-right: 0;
				}
				@include under(mobile) {
					min-width: 0rem;
				}
			}
		}
	}
	&__button {
		@extend %button;
    	margin: 28px;
		font-family: f(daxline);
		font-size: 13px;
		&:hover {
			background-color: black;
			color: c(white);
		}
    	&--cuates {
    		margin-left: 450px;
			@include under(sm) {
				display: block;
				margin-left: auto;
				margin-right: auto;
			}
    	}
	}
	&__link {
    	@extend %link;
    	padding-top: 40px;
    	&--btn {
      	color: c(dark);
    }
  }
}
