.footer {
	//comienza parche para bgc blanco... ver _patches.scss patch#2 para más detalles
	position: relative;
	//termina parche
	padding: 60px;
	background-color: c(dark);
	@media only screen and (max-width: b(md)) {
		padding: 50px 20px 70px;
	}
	&__menu {
	//grid
		&-box {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}
		&-col {
			width: 30%;
			@include under(md) { width: 50%;}
			@include under(mobile) { width: 100%; text-align: center;}
		}
	//styles
		&--ttl {
			color: c(white);
			text-transform: none;
			@include under (mobile) { padding-top: 20px;}
		}
		&--sbttl {
			font-family: f(futura);
			padding-top: 16px;
			font-weight: bold;
		}
		&--explora {
			color: c(white);
			display: block;
			line-height: 160%;
			text-transform: uppercase;
			padding-top: 16px;
			&:hover {
				text-decoration: underline;
			}
		}
		&--colabora {
			display: block;
			line-height: 160%;
			@extend %link-secondary;
			font-size: 14px;
			@include color-modifiers($is_link:true);


		}
	}
	&__form {
		&--cuates {

				&:hover {
					color: c(purple-alt)!important;
					text-decoration-color: c(purple)!important;
					text-decoration: underline;
					font-weight: bold;
				}
			}
			&--voluntarios {

				&:hover {
					color: c(orange-alt)!important;
					text-decoration-color: c(orange)!important;
					text-decoration: underline;
					font-weight: bold;
				}
			}
			&--empresas {

				&:hover {
					color: c(blue-alt)!important;
					text-decoration-color: c(blue)!important;
					text-decoration: underline;
					font-weight: bold;
				}
			}
			&--universidades {

				&:hover {
					color: c(yellow-alt)!important;
					text-decoration-color: c(yellow)!important;
					text-decoration: underline;
					font-weight: bold;
				}
			}
			&--instituciones {

				&:hover {
					color: c(aqua-alt)!important;
					text-decoration-color: c(aqua)!important;
					text-decoration: underline;
					font-weight: bold;
				}
			}
	}
	&__news {
		width: 100%;
		@media only screen and (max-width: b(md)) {
			width: 100%;
			margin: 30px 0;
			text-align: center;
		}
		&--label {
			font-family: f(futura);
			color: c(white);
			display: block;
			margin-bottom: 10px;
			text-align: center;
		}
		&--input {
			max-width: 300px;
			border: 1px solid c(aqua);
			font-size: 14px;
			&::placeholder {
				color: c(aqua);
			}
		}
		&--item {
			color: c(white);
		}
	}
	&__logo {
		width: 100%;
		text-align: center;
		padding-top: 60px;
		@media only screen and (max-width: b(md)) {
			width: 100%;
		}
		@include under(md) {padding-top: 20px;}
		&--img {
			width: 100%;
			height: auto;
			display: inline-block;
			max-width: 215px;
		}
	}
	&__social {
		width: 100%;
		display: block;
		cursor: pointer;
		text-align: center;
    	padding-bottom: 40px;
    	@include under(md) {padding: 0px;}
    	@include under(mobile) {padding: 60px 0 20px;}

		&--item {
			color: c(gray);
			padding: 10px 25px;
			display: inline-block;
			font-size: 26px !important;
			@include under(mobile) {
    			padding: 10px 15px;
    		}
			@include ease-transition;
			&:hover {
				color: c(white);
			}
			&-img {
				width: 30px;
				display: inline-block;
			}
			&-img {
				* {
					@include ease-transition;
				}
				&:hover * {
					fill: c(white);
				}
			}
		}
	}
	&__info {
		color: c(white);
		text-align: center;
		font: {
			family: f(futura);
			size: 13px;
		}
		padding-top: 60px;
		@include under(375px) {font-size: 12px;}
	}
}
