.privacy {
	&__box {
		margin: 120px 0px 120px 100px;
		@include under (xs){
			margin: 50px 0px 120px 0px;
		}
	}
	&__ttl {
		@extend %gral-title;
		padding-top: 20px;
		font-weight: bold;
		font-size: 35px;
		line-height: 45px;
		text-align: left;
		@include over (mobile){
			text-align: right;
		}
	}
	&__content {
		@extend %paragraph;
		padding-top: 60px;

	}
}
